@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  display: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
body {
  @apply bg-[#dce1e6] px-2 dark:bg-[#141414] dark:text-[#e1e3e6];
}
.content-block {
  @apply rounded-2xl bg-[#f7f8fa] p-2 dark:bg-[#222222] md:p-10;
}
.content-block-no-top-bottom-padding {
  @apply rounded-2xl bg-[#f7f8fa] p-2 dark:bg-[#222222];
}
.dark-text {
  @apply dark:text-white;
}
.head-1 {
  @apply mb-2 text-center text-2xl dark:text-white md:mb-6 md:text-4xl;
}
.head-2 {
  @apply mb-5 text-center  text-xl dark:text-white md:text-3xl;
}
.head-3 {
  @apply text-center text-2xl dark:text-white;
}
.nav-link {
  @apply -mx-3 block rounded-lg px-3 py-2  text-xl  font-semibold leading-7 text-blue-500 duration-300 hover:bg-gray-50 dark:text-[#cccccc] dark:hover:bg-slate-700 md:text-center;
}

.active {
  @apply cursor-default text-blue-700 hover:bg-inherit dark:text-blue-300 !important;
}
.shop-img {
  @apply w-2/3 rounded-lg md:w-full;
}
.pagItem {
  @apply relative inline-flex items-center px-2 py-2  text-xs font-semibold ring-1 ring-inset  ring-gray-300 hover:bg-gray-100 focus:z-20 focus:outline-offset-0  dark:text-white dark:hover:bg-gray-600 sm:px-3;
}
.pagItemActive {
  @apply relative z-10 inline-flex items-center bg-indigo-600  px-2 py-2 text-xs font-semibold text-white ring-1 ring-inset ring-gray-300 focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:px-3;
}
.form-select {
  @apply mx-2 rounded-sm p-1 ring ring-[#71aaeb] dark:bg-[#141414] dark:text-[#e1e3e6];
}
.ping-one-time {
  animation: ping 0.5s cubic-bezier(0, 0, 0.2, 1) 1;
}
.animation-delay-2 {
  animation-delay: 0.5s;
}
.animation-delay-3 {
  animation-delay: 1s;
}
.animation-delay-4 {
  animation-delay: 1.5s;
}
.animation-delay-5 {
  animation-delay: 2s;
}

.born-item {
  opacity: 0;
  animation: born 0.5s forwards;
}

/* Shop */

.totalTitle {
  @apply text-center text-base;
}

.link {
  @apply text-blue-700 dark:text-indigo-400;
}

.totalText {
  @apply text-center text-xl font-medium;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes ping {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes born {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
